import React, { useState } from 'react';
import { Box, Stack, Heading, Text, VStack, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import axios from 'axios';
import { CUST_API_URL } from '../../constants/data';
import { toast } from 'react-hot-toast';

const InteriorOfferComponent = ({ headingText, highlightedText, subText, video, headingText2, service }: {
    headingText: string;
    highlightedText: string;
    subText: string;
    video?: string;
    headingText2?: string;
    service: string;
}) => {
    const [formData, setFormData] = useState({ name: '', phone: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.name || !formData.phone) {
            setError('All fields are required.');
            return false;
        }
        if (!phoneRegex.test(formData.phone)) {
            setError('Please enter a valid phone number.');
            return false;
        }
        setError(null);
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;
        setIsLoading(true);

        try {
            const response = await axios.post(`${CUST_API_URL}/socialLeads/create/form`,{
                leads : {
                        Name: formData.name,
                        Phone: formData.phone
                }
            });
            toast.success("Request submitted. We'll get back to you soon.",{
                duration:3000
            });
            setFormData({ name: '', phone: '' });
        } catch (err) {
            console.error('Error submitting form:', err);
            toast.error('Failed to submit form. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box position="relative" w="100%" h="90vh">
            {/* React Hot Toast container */}

            {/* Video Background */}
            <video
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: '10',
                }}
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Dark Overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="black"
                opacity="0.5"
                zIndex="1"
            />

            {/* Content over Video */}
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                align="center"
                justify="space-around"
                spacing={8}
                position="relative"
                zIndex="50"
                h="90%"
                p={4}
            >
                {/* Left Section (Text) */}
                <Stack spacing={4} align="left" textAlign="left" color="white" maxW="lg">
                    <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                        {headingText}{' '}
                        <Box as="span" color="yellow.400">
                            {highlightedText}
                        </Box>{' '}
                        {headingText2}{' '}
                    </Heading>
                    <Text textAlign="left" fontSize={{ base: 'lg', md: 'xl' }}>
                        {subText}
                    </Text>
                </Stack>

                {/* Right Section (Form) */}
                <VStack
                    as="form"
                    spacing={6}
                    bg="white"
                    p={8}
                    boxShadow="lg"
                    borderRadius="md"
                    w={{ base: '100%', lg: 'md' }}
                    onSubmit={handleSubmit}
                >
                    {/* Form Title */}
                    <Heading fontSize="2xl" textAlign="center">
                        Design for every Budget
                    </Heading>

                    {/* Form Fields */}
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormControl>

                    <FormControl id="phone" isRequired>
                        <FormLabel>Phone Number</FormLabel>
                        <Input
                            type="tel"
                            name="phone"
                            placeholder="Enter your phone number"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </FormControl>

                    {/* Submit Button */}
                    <Button
                        colorScheme="teal"
                        size="lg"
                        width="100%"
                        type="submit"
                        isLoading={isLoading}
                    >
                        Get Free Quote
                    </Button>

                    {/* Disclaimer */}
                    <Text fontSize="sm" color="gray.500" textAlign="center">
                        By submitting this form, you agree to the privacy policy & terms and conditions.
                    </Text>

                    {error && (
                        <Text color="red.500" fontSize="sm" textAlign="center">
                            {error}
                        </Text>
                    )}
                </VStack>
            </Stack>
        </Box>
    );
};

export default InteriorOfferComponent;
