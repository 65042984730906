import React, { useEffect, useRef, ReactNode, useState } from "react";

interface SliderProps {
  children: ReactNode;
  interval?: number;
  className?: string;
  itemsPerView?: number; // New prop to control number of items visible
}

const Slider: React.FC<SliderProps> = ({
  children,
  interval = 3000,
  className = "",
  itemsPerView = 4,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [childWidth, setChildWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState("80%");

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth >= 1024) {
        // PC screens
        setItemWidth(`calc(100% / ${itemsPerView} - 16px)`);
      } else {
        // Mobile screens
        setItemWidth("100%");
      }
    };

    updateWidth(); // Set the initial width

    // Add a resize event listener to update the width on window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [itemsPerView]);

  useEffect(() => {
    const slider = sliderRef.current;
    const firstChild = slider?.children[0] as HTMLDivElement;
    if (firstChild) {
      const computedStyle = getComputedStyle(firstChild);
      const marginLeft = parseFloat(computedStyle.marginLeft);
      const marginRight = parseFloat(computedStyle.marginRight);
      const totalMargin = marginLeft + marginRight;
      const totalWidth = firstChild.offsetWidth + totalMargin;
      setChildWidth(totalWidth);
    }

    let intervalId: NodeJS.Timeout;

    const startAutoScroll = () => {
      intervalId = setInterval(() => {
        if (slider) {
          // Scroll to the left
          slider.scrollBy({ left: childWidth, behavior: "smooth" });

          // Reset to the start when reaching the end
          if (slider.scrollLeft + slider.offsetWidth >= slider.scrollWidth) {
            slider.scrollTo({ left: 0, behavior: "smooth" });
          }
        }
      }, interval);
    };

    startAutoScroll();

    return () => {
      clearInterval(intervalId);
    };
  }, [childWidth, interval]);

  return (
    <div className={`overflow-hidden ${className}`}>
      <div
        ref={sliderRef}
        className="flex overflow-x-auto whitespace-nowrap section scroll-hide scroll-smooth"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className="flex-shrink-0 m-2 rounded-lg justify-center items-center flex"
            style={{ width: itemWidth }} // Inline width based on screen size
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
