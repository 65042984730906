import React from 'react'
import { FiMail } from 'react-icons/fi'

const Banner = () => {
    return (
        <div className='mt-10 w-full md:w-auto '>
            <span className=' flex realtive items-center justify-center md:ml-10 '>

                <div className='flex md:w-auto w-full gap-5 p-3 text-white md:px-8 md:pl-14 md:pr-4 relative md:text-4xl text-xl font-bold bg-green-800'>
                    <img
                        src={require("../../assets/offerings/interior/offer.png")}
                        className='md:absolute md:-left-36  md:-top-3 w-28 md:w-44 '
                        alt=""
                    />
                    <div className='md:flex justify-center items-center gap-2'>
                        <h1 className=''>Transform Your Space for Only{"  "}</h1>
                        <span className='flex my-2 gap-2'>

                            <h1 className='text-white decoration-red-600 decoration-2 line-through md:mx-3'>₹21.49/SqFt</h1>
                            <h1 className='text-yellow-400'>₹18.99/SqFt.</h1>

                        </span>
                    </div>
                </div>
            </span>

            <div className='flex gap-2 my-5 items-center justify-center'>
                <span className='bg-red-500 rounded-full p-2'>
                    <FiMail color='white' size={25} />
                </span>
                <a
                target="_blank"  
                href="https://api.whatsapp.com/send?phone=+916363759171&text=Hello,%20I%20would%20like%20to%20know%20more%20about%20Your%Designs" 
                className='bg-red-500 px-3 py-1.5 text-2xl text-white font-semibold rounded-box'>Contact Us</a>
            </div>
        </div>
    )
}

export default Banner