import React from 'react';
import {
  Heading, Text, Stack, Grid, GridItem, Box, Avatar, useBreakpointValue,
  Image
} from '@chakra-ui/react';

const HowItWorksSection = ({ title, steps }) => {
  const gridColumns = useBreakpointValue({ base: 1, md: 2 }); // Responsive columns

  return (
    <Box py={12} px={6}>
      {/* Dynamic Section Title */}
      <Heading textAlign="center" mb={8}>
        {title}
      </Heading>

      {/* Dynamic Grid Layout for Steps */}
      <Grid templateColumns={`repeat(${gridColumns}, 1fr)`} px={{ base: 4, md: 20 }} gap={8}>
        {steps.map((step, index) => (
          <GridItem key={index} p={4}>
          <Stack direction="row" spacing={4} alignItems="center">
            <Image
              src={step.image}
              alt={step.title}
              boxSize="80px"
              objectFit="contain"
              ml={0}  // No margin to the left
            />
            <Stack spacing={2} flex={1} pl={4}> {/* Add padding-left to ensure equal spacing */}
              <Heading fontSize="lg" color="gray.700">{step.title}</Heading>
              <Text color="gray.500">{step.description}</Text>
            </Stack>
          </Stack>
        </GridItem>
        
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorksSection;
