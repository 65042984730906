import  { useRef, useEffect, useState } from 'react';
import { useSpring, animated, easings } from '@react-spring/web';
import React from 'react';

const NumberAnimation = ({ number, isPercentage = false, duration = 3000 } : {number : number, isPercentage?: boolean, duration?: number}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const props = useSpring({
    from: { number: 0 },
    to: { number },
    config: { duration: duration, easing: easings.easeInCubic }, // Added ease-in effect
    reset: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      <h1 className=" text-center font-black text-[25px] md:text-[35px] py-1">
        <animated.span>
          {props.number.to((n) => n.toFixed(0))}
        </animated.span>
        <span className="text-[#FFBC11] font-[800]">
          {isPercentage ? '%' : '+'}
        </span>
      </h1>
    </div>
  );
};

export default NumberAnimation;
