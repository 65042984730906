import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase/firebase';
import { AppContext } from '../context/AppContext';

interface ImageUploaderProps {
    onUpload: (urls: string[]) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload }) => {
    const {raiseToast} = React.useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        setLoading(true); // Start loading
        const files = Array.from(event.target.files);
        const uploadPromises = files.map(async (file) => {
            const storageRef = ref(storage, `vendor-images/${file.name}`);
            await uploadBytes(storageRef, file);
            return getDownloadURL(storageRef);
        });

        try {
            const urls = await Promise.all(uploadPromises);
            onUpload(urls);
            raiseToast('Images uploaded successfully', 'success');
        } catch (error) {
            raiseToast('Failed to upload images', 'error');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div>
            <input
                type="file"
                multiple
                onChange={handleImageUpload}
                className="file-input file-input-bordered file-input-sm w-full"
                disabled={loading} // Disable input while loading
            />
            {loading && (
                <button className="btn btn-sm btn-secondary loading mt-2" disabled>
                    Uploading...
                </button>
            )}
        </div>
    );
};

export default ImageUploader;
