export const GalleryImages = {
    interior: {
        commercial: [...Array(24)].map((_, index) => ({
            name: 'Commercial Interior',
            image: require(`../assets/gallery/interior/commercial/interior-commercial-${index + 1}.jpg`) // Spaces are preserved in the path
        })),
        
        residential: [...Array(52)].map((_, index) => ({
            name: 'Residential Interior',
            image: require(`../assets/gallery/interior/residential/interior-residential-${index + 1}.jpg`) // Spaces are preserved in the path
        })),
    },
    architecture: {
        commercial: [...Array(18)].map((_, index) => ({
            name: 'Commercial Architecture',
            image: require(`../assets/gallery/architecture/commercial/architecture-commercial-${index + 1}.jpg`) // Spaces are preserved in the path
        })),
        
        residential: [...Array(29)].map((_, index) => ({
            name: 'Residential Architecture',
            image: require(`../assets/gallery/architecture/residential/architecture-residential-${index + 1}.jpg`) // Spaces are preserved in the path
        }))
    }
};
