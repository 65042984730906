import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { data } from "../../assets/offers/StatesCitiesData";


type StatesCitiesData = {
    [key: string]: string[]; 
};

const statesAndCities: StatesCitiesData = data;

const FormComponent: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        state: "",
        city: "",
        requirement: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        phone: "",
        email: "",
        state: "",
        city: "",
        requirement: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validate = () => {
        const newErrors = {
            name: formData.name ? "" : "Name is required",
            phone: formData.phone && /^\d{10}$/.test(formData.phone) ? "" : "Phone number must be 10 digits",
            email: formData.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? "" : "Email is invalid",
            state: formData.state ? "" : "State is required",
            city: formData.city ? "" : "City is required",
            requirement: formData.requirement ? "" : "Requirement is required",
        };
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            try {
                // Mock API call, replace with your actual API call
                await new Promise((resolve) => setTimeout(resolve, 2000));
                // console.log("Form Submitted", formData);
                navigate("/lp/thanks"); 
            } catch (error) {
                console.error("Submission failed", error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="md:w-[60%] w-full mx-auto md:my-8 mb-10 p-4 bg-white shadow-lg rounded-lg">
            <div className=" border-l-4 border-yellow-400 pl-4">
                <h1 className="text-2xl font-bold mb-2 text-green-600">Contact Us</h1>
                <p className="mb-4 text-gray-600 text-sm">Please fill out the form below with your details.</p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">Name</span>
                    </label>
                    <input
                        type="text"
                        name="name"
                        className={`input input-bordered w-full input-sm ${errors.name ? "input-error" : ""}`}
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                </div>

                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">Phone Number</span>
                    </label>
                    <input
                        type="text"
                        name="phone"
                        className={`input input-bordered w-full input-sm ${errors.phone ? "input-error" : ""}`}
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
                </div>

                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">Email</span>
                    </label>
                    <input
                        type="email"
                        name="email"
                        className={`input input-bordered w-full input-sm ${errors.email ? "input-error" : ""}`}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                </div>

                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">State</span>
                    </label>
                    <select
                        name="state"
                        className={`select select-bordered w-full select-sm ${errors.state ? "select-error" : ""}`}
                        value={formData.state}
                        onChange={handleChange}
                    >
                        <option value="">Select State</option>
                        {Object.keys(statesAndCities).map((state) => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                    {errors.state && <p className="text-red-500 text-xs">{errors.state}</p>}
                </div>

                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">City</span>
                    </label>
                    <select
                        name="city"
                        className={`select select-bordered w-full select-sm ${errors.city ? "select-error" : ""}`}
                        value={formData.city}
                        onChange={handleChange}
                        disabled={!formData.state}
                    >
                        <option value="">Select City</option>
                        {formData.state &&
                            statesAndCities[formData.state].map((city : string) => (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            ))}
                    </select>
                    {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
                </div>

                <div className="form-control mb-2">
                    <label className="label text-green-600">
                        <span className="label-text">Requirement</span>
                    </label>
                    <textarea
                        name="requirement"
                        className={`textarea textarea-bordered w-full textarea-sm ${errors.requirement ? "textarea-error" : ""}`}
                        value={formData.requirement}
                        onChange={handleChange}
                    />
                    {errors.requirement && <p className="text-red-500 text-xs">{errors.requirement}</p>}
                </div>

                <button
                    type="submit"
                    className={`btn btn-green w-full mt-3 btn-sm ${loading ? "loading" : ""}`}
                    disabled={loading}
                >
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </form>
        </div>
    );
};

export default FormComponent;
