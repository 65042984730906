import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFound = () => {
    return (
        <div className='flex flex-col h-screen'>
            <Header />
            <div className="flex flex-col justify-center items-center h-[65%] shrink-0 text-center">
                <h2 className="text-4xl font-bold mb-4">404 - Page Not Found</h2>
                <p className="mb-6">Sorry, the page you're looking for doesn't exist.</p>
                <Link to="/" className="text-blue-500 hover:underline">
                    Go to Home Page
                </Link>
            </div>
            <Footer />
        </div>
    );
};

export default NotFound;