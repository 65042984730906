import React from "react";
import { IoCall } from "react-icons/io5";

const OfferHeader = () => {

    return (
        <div
            className={` top-0 w-full p-3 transition-colors duration-300 bg-opacity-50  backdrop-blur z-50`}
        >
            <div className="container mx-auto flex items-center justify-evenly">
                <div className="">
                    <img src={require("../../assets/offers/navlogo.svg").default} alt="Logo" className="md:h-10 h-7" />
                </div>
                <div className="text-white flex items-center space-x-4">
                    <span className=" animate-bounce">
                        <IoCall color="#384B70" size={25} />
                    </span>
                    <a href="https://api.whatsapp.com/send?phone=+916363759171&text=Hello,%20I%20would%20like%20to%20know%20more%20about%20Your%20Designs">
                        <button type="button" className="text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg  px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 me-2 mb-2">
                            Contact
                        </button>
                    </a>
                </div>
            </div>
        </div >
    );
};

export default OfferHeader;
