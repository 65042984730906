import React from 'react';
import {
  Box,
  Heading,
  Stack,
  Avatar,
  Text,
  HStack,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import Slider from '../SliderWrapper';

const ReviewsSection = ({ reviewsData }) => {
  return (
    <Box bg="gray.50">
      <Box maxW="7xl" mx="auto" py={12} px={6}>
        <Heading textAlign="center" mb={8}>
          What Our Customers Say
        </Heading>
        <Slider interval={3000} className="my-slider">
          {reviewsData.map((review, index) => (
            <Box
              key={index}
              w={{ md: "100%", base: "80%" }}
              h="300px"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              position="relative"
              shadow="md"
              bg="white"
              flexShrink={0}
              p="4"
            >
              <Stack spacing={3} align="center">
                <Avatar name={review.name} src={review.avatar} />
                <Text fontWeight="bold">{review.name}</Text>
                <HStack>
                  {[...Array(review.rating)].map((_, i) => (
                    <StarIcon key={i} color="yellow.400" />
                  ))}
                </HStack>
                <Text 
                  mt={4} 
                  textAlign="center" 
                  maxW="90%"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="normal"
                >
                  {review.review}
                </Text>
              </Stack>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ReviewsSection;
