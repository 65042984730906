import React from 'react';
import Slider from './SliderWrapper';
export default function ClientSay() {
    const testimonials = [
        {
            testimonial: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
            name: "Mitali Sinha",
            imageSrc: require("../assets/testimonials/mitali-sinha.jpeg"),
        },
        {
            testimonial: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
            name: "P. Gowda",
            imageSrc: require("../assets/testimonials/p-gowda.jpeg"),
        },
        {
            testimonial: "They listened, they delivered. Impressed by their service.",
            name: "Nitin Prajapat",
            imageSrc: require("../assets/testimonials/nitin-prajapat.jpeg"),
        },
        {
            testimonial: "Fantastic architecture design & affordable.",
            name: "Snehal Raj",
            imageSrc: require("../assets/testimonials/snehal-raj.jpeg"),
        },
    ];

    return (
        <div id='reviews' className="relative bg-gradient-to-r from-[#2c4557] to-[#2c4557] py-2">
            <img
                src={require("../assets/architecture/vector1.png")}
                alt="Vector"
                className="absolute w-[10%] left-0 z-10"
            />

            <div className='w-11/12 md:w-10/12 mx-auto my-6 flex items-center justify-center'>
                <h1 className='font-bold text-4xl md:text-3xl text-white text-center'>
                    What our Customer Says
                </h1>
            </div>

            <div className=' mx-auto overflow-hidden'>
                <Slider
                    interval={3000}
                    className=" p-2 rounded-lg bg-gradient-to-r from-transparent duration-500 transition-all"
                >
                    {testimonials.map((item, index) => (
                        <TestimonialCard
                            key={index}
                            testimonial={item.testimonial}
                            name={item.name}
                            imageSrc={item.imageSrc}
                        />
                    ))}
                </Slider>
            </div>

            <img
                src={require("../assets/architecture/vector2.png")}
                alt="Vector"
                className="w-[10%] absolute bottom-0 right-0 z-10 hidden md:block"
            />
        </div>
    );
}

const TestimonialCard = ({ testimonial, name, imageSrc }) => {
    return (
        <div className="mx-2 p-5 h-80  bg-white rounded-xl ">
              <div className="flex flex-col items-center justify-center my-4 gap-3">
                <img
                    src={imageSrc}
                    width={70}
                    height={70}
                    alt={name}
                    className="rounded-full border-2 border-[#7AD9BA]"
                />
                <h1 className="text-[#c4b819]  font-semibold text-xl">{name}</h1>
            </div>
            <p className=" text-wrap w-56 px-2 italic text-center  rounded-r-[50px] rounded-tl-[50px] text-black">
                {'"'+testimonial+'"'}
            </p>
          
        </div>
    );
};

