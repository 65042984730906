import React from 'react'; 
import NumberAnimation from '../NumberAnimation';
import { Heading } from '@chakra-ui/react';

const NumbersSection = ({ title, numbers }) => {
  return (
    <section className="bg-green-800 py-12">
      {/* Dynamic Section Title */}
      <Heading color="white" textAlign="center" mb={8}>
        {title}
      </Heading>

      {/* Dynamic Numbers List */}
      <div className="flex flex-col md:flex-row justify-around items-center">
        {numbers.map((item, index) => (
          <div className="text-center" key={index}>
            <NumberAnimation 
              symbol={item.symbol} 
              duration={item.duration} 
              number={item.number} 
              className="text-4xl md:2xl font-bold text-white" 
            />
            <p className="md:text-lg text-white">{item.label}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NumbersSection;
