"use client";

import React from "react";
import Lottie from "lottie-react";
import * as animationData from "../../assets/lottie/loader/loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function MainLoading() {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[100000] flex justify-center items-center">
      <div className=" absolute rounded-2xl overflow-hidden w-11/12 md:w-1/3 mx-auto">
        <Lottie {...defaultOptions} className="w-full " />
        {/* <Link
          href={"/"}
          className="rounded-xl bg-green-600 py-2 px-3 text-white"
        >
          Go to Home
        </Link> */}
      </div>
    </div>
  );
}
