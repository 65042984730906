import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';


const Thanks = () => {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
        navigate("/"); 
    }
  }, [countdown]);

  return (
    <div className='min-h-screen flex flex-col'>
      <Header />
      <div className="flex-grow shrink-0 flex flex-col items-center justify-center px-10 bg-image-1 text-center text-white">
        <div   className="text-2xl text-yellow-400 md:text-4xl font-semibold tracking-wide"
        >
          Thank you for trusting us with your project!
        </div>
        <p className="mt-4 text-lg">
          You will be redirected in <span className="font-bold">{countdown}</span> seconds...
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Thanks;
