import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.css'

const rootElement: any = document.getElementById('root');


ReactDOM.createRoot(rootElement).render(
    <App />
);