import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Contact() {
  return (
    <>
      <Header />
      <div className="w-11/12 md:w-10/12 mx-auto py-8 text-black">
        <h1 className="font-black text-[2rem] md:text-[3rem] my-3 font-[Italiana]">
          Contact Us
        </h1>
        <div className="">
          <div className="">
            <div className="w-full md:w-[50%]">
              <h2 className="font-bold text-xl my-3">
                Merchant Legal entity name:
              </h2>
              <p>Vrisan Services (India)</p>

              <h2 className="font-bold text-xl my-3">Registered Address:</h2>
              <p>
                13th Cross, V.P Nagar, Bengaluru, Karnataka-560068
              </p>

              <h2 className="font-bold text-xl my-3">Operational Address:</h2>
              <p>
                VTP, Sarjapur Main Rd, Bellandur, Bengaluru, Karnataka 560103
              </p>
            </div>
            <div className="w-full">
              <h2 className="font-bold text-xl my-3">Email</h2>

              <a href="mailto:care@designelementary.com">
                care@designelementary.com
              </a>
            </div>
            <div className="w-full">
              <h2 className="font-bold text-xl my-3">Phone</h2>

              <a href="tel:+9180486369670">+91 80486369670</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
}
