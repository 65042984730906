const data = {
  topSection: {
    headingText: "Revitalize your home's exterior ",
    highlightedText: "designs that honor",
    subText: "Discover unparalleled creativity and reliable service with NatureCraft",
    headingText2: "your vision and budget",
    video : require('../assets/design-ideas/Exterior/video.mp4')
  },

  features: [
    {
      title: 'Façade design and renovation',
      image: require('../assets/design-ideas/Exterior/key-features/1.png')
    },
    {
      title: 'Energy-efficient solutions',
      image: require('../assets/design-ideas/Exterior/key-features/2.png')
    },
    {
      title: 'Structural enhancements',
      image: require('../assets/design-ideas/Exterior/key-features/3.png')
    },
    {
      title: 'Aesthetic improvement plans',
      image: require('../assets/design-ideas/Exterior/key-features/4.png')
    },
  ],

  numbers: [
    { number: 50, label: 'Interior Experts', symbol: '+', duration: 2500 },
    { number: 60, label: 'Location Served', symbol: '+', duration: 2500 },
    { number: 1000, label: 'Happy Clients', symbol: '+', duration: 2500 },
  ],

  stepsData: [
    {
      title: 'Client Meeting',
      description: 'Understand exterior design requirements.',
      image: require('../assets/design-ideas/Exterior/how-it-works/1.png')
    },
    {
      title: 'Site Evaluation',
      description: 'Examine site for exterior possibilities.',
      image: require('../assets/design-ideas/Exterior/how-it-works/2.png')
    },
    {
      title: 'Design Proposal',
      description: 'Present exterior design concepts.',
      image: require('../assets/design-ideas/Exterior/how-it-works/3.png')
    },
    {
      title: 'Material Selection',
      description: 'Choose appropriate materials and finishes.',
      image: require('../assets/design-ideas/Exterior/how-it-works/4.png')
    },
    {
      title: 'Execution',
      description: 'Implement exterior design plan.',
      image: require('../assets/design-ideas/Exterior/how-it-works/5.png')
    },
    {
      title: 'Final Walkthrough',
      description: 'Review completed exterior work with client.',
      image: require('../assets/design-ideas/Exterior/how-it-works/6.png')
    },
  ],

  faqs: [
    {
      question: 'What does exterior design include?',
      answer:
        'Exterior design involves planning and styling the outer appearance of a building, including facades, color schemes, landscaping, outdoor lighting, and material selection.',
    },
    {
      question: 'Can you help with both residential and commercial exterior designs?',
      answer:
        'Yes, we offer services for both residential and commercial properties, tailoring designs to meet your style, needs, and budget.',
    },
    {
      question: 'How do you approach the design of a building’s exterior?',
      answer:
        'We begin with a consultation to understand your preferences and functional needs, then incorporate elements like architecture, sustainability, and landscaping for a cohesive design.',
    },
    {
      question: 'Can you incorporate eco-friendly elements into exterior designs?',
      answer:
        'Yes, we can include eco-friendly materials, energy-efficient lighting, sustainable building materials, and green landscaping solutions in your design.',
    },
    {
      question: 'How long does the exterior design process take?',
      answer:
        'The timeline varies by project scope. After a consultation, we provide a timeline outlining the stages from design to execution, which could range from weeks to months.',
    },
    {
      question: 'Do you offer 3D renderings for exterior designs?',
      answer:
        'Yes, we provide 3D renderings to help you visualize the design before construction, allowing for adjustments to ensure it meets your expectations.',
    },
    {
      question: 'What factors should I consider when choosing an exterior design?',
      answer:
        'Consider factors like architectural style, climate, maintenance, energy efficiency, and personal preferences. We’ll guide you through these considerations.',
    },
    {
      question: 'How do I get started with your exterior design services?',
      answer:
        'Contact us for a consultation where we’ll discuss your vision, assess your property, and provide design ideas that align with your goals.',
    },
  ],
  reviews: [
    {
      review: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
      name: "Mitali Sinha",
      avatar: require("../assets/testimonials/mitali-sinha.jpeg"),
      rating: 5
    },
    {
      review: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
      name: "P. Gowda",
      avatar: require("../assets/testimonials/p-gowda.jpeg"),
      rating: 4
    },
    {
      review: "They listened, they delivered. Impressed by their service.",
      name: "Nitin Prajapat",
      avatar: require("../assets/testimonials/nitin-prajapat.jpeg"),
      rating: 4
    },
    {
      review: "Fantastic architecture design & affordable.",
      name: "Snehal Raj",
      avatar: require("../assets/testimonials/snehal-raj.jpeg"),
      rating: 5
    },
  ]
};

export default data;
