import { Box, SimpleGrid, Image, Text, Heading } from '@chakra-ui/react';
import React from 'react';

const KeyFeatures = ({ features, title }) => {
  return (
    <Box py={10} textAlign="center">
      <Heading as="h2" size="xl" mb={6}>
        {title}
      </Heading>
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={10}>
        {features.map((feature, index) => (
          <Box key={index}>
            <Image
              src={feature.image}
              alt={feature.title}
              boxSize="80px"
              objectFit="contain"
              mx="auto"
            />
            <Text
              fontWeight="bold"
              fontSize={{ base: 'sm', md: 'md' }} // Smaller on mobile, default size on larger screens
              mt={4}
            >
              {feature.title}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default KeyFeatures;
