const data = {
  topSection: {
    headingText: "Build your dream space with",
    highlightedText: "end-to-end solutions",
    headingText2: "that match your vision",
    subText: "Experience seamless construction and timely completion with Design Elementary",
    video: require('../assets/other-services/TurnKey/video.mp4')
  },
  features: [

    {
      title: 'End-to-end project management',
      image: require('../assets/other-services/TurnKey/key-features/1.png')
    },
    {
      title: 'Comprehensive planning and design',
      image: require('../assets/other-services/TurnKey/key-features/2.png')
    },
    {
      title: 'Cost estimation and budgeting',
      image: require('../assets/other-services/TurnKey/key-features/3.png')
    },
    {
      title: 'Material procurement and logistics',
      image: require('../assets/other-services/TurnKey/key-features/4.png')
    },
  ],

  numbers: [
    { number: 50, label: 'Interior Experts', symbol: '+', duration: 2500 },
    { number: 60, label: 'Location Served', symbol: '+', duration: 2500 },
    { number: 1000, label: 'Happy Clients', symbol: '+', duration: 2500 },
  ],

  stepsData: [
    {
      title: 'Project Briefing',
      description: 'Detailed discussion of project requirements.',
      image: require('../assets/other-services/TurnKey/how-it-works/1.png')
    },
    {
      title: 'Design Development',
      description: 'Create comprehensive design plans.',
      image: require('../assets/other-services/TurnKey/how-it-works/2.png')
    },
    {
      title: 'Approval & Permits',
      description: 'Obtain necessary approvals and permits.',
      image: require('../assets/other-services/TurnKey/how-it-works/3.png')
    },
    {
      title: 'Procurement',
      description: 'Source materials and contractors.',
      image: require('../assets/other-services/TurnKey/how-it-works/4.png')
    },
    {
      title: 'Construction',
      description: 'Manage full-scale construction process.',
      image: require('../assets/other-services/TurnKey/how-it-works/5.png')
    },
    {
      title: 'Handover',
      description: 'Deliver completed project to client.',
      image: require('../assets/other-services/TurnKey/how-it-works/6.png')
    },
  ],

  faqs: [
    {
      question: 'What is a turnkey project?',
      answer:
        'A turnkey project is a complete solution where we handle every aspect, from design and planning to execution and final delivery, ensuring the project is ready for immediate use without managing individual tasks.',
    },
    {
      question: 'What are the benefits of opting for a turnkey project?',
      answer:
        'Turnkey projects offer a single point of contact, streamlined management, cost efficiency, reduced risks, timely completion, and convenience without managing multiple contractors or phases.',
    },
    {
      question: 'What industries can benefit from turnkey project services?',
      answer:
        'Industries like construction, real estate, hospitality, manufacturing, healthcare, IT, and retail can benefit from turnkey solutions, ideal for end-to-end project delivery.',
    },
    {
      question: 'What is included in a turnkey project?',
      answer:
        'A turnkey project includes planning, design, procurement, construction, installation, quality control, and delivery. We handle everything from start to finish, meeting specifications, timelines, and budgets.',
    },
    {
      question: 'How do you ensure quality control in turnkey projects?',
      answer:
        'We employ experienced professionals, use high-quality materials, follow industry standards, conduct regular inspections, ensure compliance with safety regulations, and provide updates throughout the project for transparency.',
    },
  ],

  reviews: [
    {
      review: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
      name: "Mitali Sinha",
      avatar: require("../assets/testimonials/mitali-sinha.jpeg"),
      rating: 5
    },
    {
      review: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
      name: "P. Gowda",
      avatar: require("../assets/testimonials/p-gowda.jpeg"),
      rating: 4
    },
    {
      review: "They listened, they delivered. Impressed by their service.",
      name: "Nitin Prajapat",
      avatar: require("../assets/testimonials/nitin-prajapat.jpeg"),
      rating: 4
    },
    {
      review: "Fantastic architecture design & affordable.",
      name: "Snehal Raj",
      avatar: require("../assets/testimonials/snehal-raj.jpeg"),
      rating: 5
    },
  ]
};

export default data;
