//@ts-nocheck 
import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import image from "../assets/sign-in.jpg"
import logo from "../assets/navlogo.svg"

import * as Yup from 'yup';
const B2BPartner: React.FC = () => {
  const initialValues = {
    directorName: '',
    companyName: '',
    industry: '',
    numberOfProjectsPerYear: '',
    projectSize: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    emailId: '',
    city: '',
    address: '',
    pincode: '',
    requirement: '',
    howSoon: '',
  };

  const [success, setSuccess] = React.useState(false);

  const validationSchema = Yup.object({
    directorName: Yup.string().required('Required'),
    companyName: Yup.string(),
    industry: Yup.string().required('Required'),
    numberOfProjectsPerYear: Yup.string(),
    projectSize: Yup.string(),
    phoneNumber: Yup.string().required('Required'),
    alternatePhoneNumber: Yup.string(),
    emailId: Yup.string().email('Invalid email format'),
    city: Yup.string().required('Required'),
    address: Yup.string(),
    pincode: Yup.string(),
    requirement: Yup.string().required('Required'),
    howSoon: Yup.string(),
  });

  const handleSubmit = (values: any) => {
    fetch("https://script.google.com/macros/s/AKfycbyBYugs7sRpFWna-QbboYxfpg35-FXH8LRE59gvsIcRVqiOM8Lfv1kVylFaAeYVCw3U/exec", {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then(response => response.json())
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };



  return (
    <div className="flex flex-col justify-between items-center min-h-screen w-full bg-gray-100">

      <div className='w-full relative h-32 flex justify-center items-center  '>

        <img src={logo} className='absolute w-72 z-10' alt="" />
        <div className='absolute w-full h-full bg-teal-200 opacity-70'></div>
        <img
          src={image}
          className="w-full object-cover h-full"
          alt=""
        />
      </div>

      <div className="card w-full max-w-xl md:shadow-lg md:bg-white mt-10  mx-2 ">
        {success ?
          <div className='text-center text-2xl text-zinc-600 py-2'>
            Form Submitted. Thank You!
          </div>
          : <div className="card-body">

            <h1 className=' font-semibold card-title text-orange-400 '>B2B Services/Parternships</h1>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-control mb-2">
                    <label htmlFor="directorName" className="label">
                      <span className="label-text font-semibold">Director / Owner Name*</span>
                    </label>
                    <Field name="directorName" type="text" className="input input-bordered" />
                    <ErrorMessage name="directorName" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="companyName" className="label">
                      <span className="label-text font-semibold">Company Name</span>
                    </label>
                    <Field name="companyName" type="text" className="input input-bordered" />
                    <ErrorMessage name="companyName" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label className="label">
                      <span className="label-text font-semibold">Industry*</span>
                    </label>
                    <div className="flex flex-col md:flex-row justify-between gap-2">
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="industry"
                          value="Interior Services"
                          className="radio radio-primary"
                        />
                        <span className="ml-2">Interior Services</span>
                      </label>
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="industry"
                          value="Construction Services"
                          className="radio radio-primary"
                        />
                        <span className="ml-2">Construction Services</span>
                      </label>
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="industry"
                          value="Both"
                          className="radio radio-primary"
                        />
                        <span className="ml-2">Both</span>
                      </label>
                    </div>
                    <ErrorMessage name="industry" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="numberOfProjectsPerYear" className="label">
                      <span className="label-text font-semibold">Number of Projects per year</span>
                    </label>
                    <Field name="numberOfProjectsPerYear" as="select" className="select select-bordered">
                      <option value="">Select</option>
                      <option value="5-10">5-10</option>
                      <option value="10-15">10-15</option>
                      <option value="15-20">15-20</option>
                      <option value="20+">20+</option>
                    </Field>
                    <ErrorMessage name="numberOfProjectsPerYear" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="projectSize" className="label">
                      <span className="label-text font-semibold">Project Size</span>
                    </label>
                    <Field name="projectSize" as="select" className="select select-bordered">
                      <option value="">Select</option>
                      <option value="Rs.2-6L">Rs.2-6L</option>
                      <option value="Rs.6L-10L">Rs.6L-10L</option>
                      <option value="Rs.10L-15L">Rs.10L-15L</option>
                      <option value="Rs.15L-20L">Rs.15L-20L</option>
                      <option value="Rs.20L+">Rs.20L+</option>
                    </Field>
                    <ErrorMessage name="projectSize" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="phoneNumber" className="label">
                      <span className="label-text font-semibold">Phone Number*</span>
                    </label>
                    <Field name="phoneNumber" type="text" className="input input-bordered" />
                    <ErrorMessage name="phoneNumber" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="alternatePhoneNumber" className="label">
                      <span className="label-text font-semibold">Alternate Phone Number</span>
                    </label>
                    <Field name="alternatePhoneNumber" type="text" className="input input-bordered" />
                    <ErrorMessage name="alternatePhoneNumber" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="emailId" className="label">
                      <span className="label-text font-semibold">Email Id</span>
                    </label>
                    <Field name="emailId" type="email" className="input input-bordered" />
                    <ErrorMessage name="emailId" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="city" className="label">
                      <span className="label-text font-semibold">City*</span>
                    </label>
                    <Field name="city" type="text" className="input input-bordered" />
                    <ErrorMessage name="city" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="address" className="label">
                      <span className="label-text font-semibold">Address</span>
                    </label>
                    <Field name="address" type="text" className="input input-bordered" />
                    <ErrorMessage name="address" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="pincode" className="label">
                      <span className="label-text font-semibold">Pincode</span>
                    </label>
                    <Field name="pincode" type="text" className="input input-bordered" />
                    <ErrorMessage name="pincode" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="requirement" className="label">
                      <span className="label-text font-semibold">Requirement*</span>
                    </label>
                    <Field name="requirement" as="select" className="select select-bordered">
                      <option value="">Select</option>
                      <option value="Design Services">Design Services</option>
                      <option value="Execution Partnerships">Execution Partnerships</option>
                      <option value="Product/Material Partnership">Product/Material Partnership</option>
                    </Field>
                    <ErrorMessage name="requirement" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2">
                    <label htmlFor="howSoon" className="label">
                      <span className="label-text font-semibold">How soon you need</span>
                    </label>
                    <Field name="howSoon" as="select" className="select select-bordered">
                      <option value="">Select</option>
                      <option value="Immediate">Immediate</option>
                      <option value="1 month">1 month</option>
                      <option value="2-3 month">2-3 month</option>
                    </Field>
                    <ErrorMessage name="howSoon" component="div" className="text-red-500" />
                  </div>

                  <div className="form-control mb-2 mt-6">
                    <button type="submit" className="btn btn-primary w-full" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
      </div>

      <div className='my-10 '>
        © Copyright Design Elementary 2024
      </div>
    </div>
  );
};

export default B2BPartner;