import React from "react";
import AppContextInterface from "../interface/AppContext";
import MainLoading from "../components/loader/MainLoading";
import ChildrenInterface from "../interface/Children";
import { useToast } from "@chakra-ui/react";

export const AppContext = React.createContext<AppContextInterface>(
  {} as AppContextInterface
);

export default function AppContextProvider(props: ChildrenInterface) {

  const toast = useToast();


  const [loading, setLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<any>({});
  const [emailSendAnimation, setEmailSendAnimation] =
    React.useState<boolean>(false);

  function setLoadingState(state: boolean) {
    setLoading(state);
  }

  function setEmailSendAnimationState(state: boolean) {
    setEmailSendAnimation(state);
  }

  function setUserCookie(data: any, host: string) {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie = `Id=${JSON.stringify(
      data.Id
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `Token=${JSON.stringify(
      data.Token
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `Email=${JSON.stringify(
      data.Email
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `Session=${JSON.stringify(
      data.Session
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `Name=${JSON.stringify(
      data.Name
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `RecId=${JSON.stringify(
      data.RecId
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `PCode=${JSON.stringify(
      data.PCode
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
    document.cookie = `Phone=${JSON.stringify(
      data.Phone
    )}; expires=${date.toUTCString()}; path=/;Domain=.${host};Secure;SameSite=None;`;
  }

  const setDataForUser = React.useMemo(() => (data: any) => {
    setUser({
      Id: data.Id,
      Token: data.Token,
      Name: data.Name,
      Email: data.Email,
      Session: data.Session,
      RecId: data.RecId,
      PCode: data.PCode,
      Phone: data.Phone,
    });
    localStorage.setItem("user", JSON.stringify(data));
    const host = window.location.hostname;
    // remove any subdomain
    const domain = host.split(".").slice(-2).join(".");
    setUserCookie(data,domain);
  }, []);

  function raiseToast(title: string, status: string, message?: string) {
    toast({
      title: title,
      description: message || "",
      status: status as any,
      duration: 5000,
      isClosable: true,
      position: "bottom-left",
    });
  }

  React.useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user") || "{}"));
  }, []);


  const fetchUserDetails = React.useCallback(async () => {
    setLoading(true);
    try {
      let user = JSON.parse(localStorage.getItem("user") || "{}");

      if (user.Id === undefined) {
        user = {
          Id: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Id\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          Token: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Token\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          Email: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Email\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          Session: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Session\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          Name: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Name\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          RecId: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)RecId\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          PCode: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)PCode\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          Phone: JSON.parse(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)Phone\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
        };
      }

      if (
        user.Id === undefined ||
        user.Id === "" ||
        user.Id === null ||
        user.Token === undefined ||
        user.Token === "" ||
        user.Token === null ||
        user.Session === undefined ||
        user.Session === "" ||
        user.Session === null ||
        user.Phone === undefined ||
        user.Phone === "" ||
        user.Phone === null

      ) {
        Logout();
      }
      else {
        setDataForUser(user);
      }

    } catch (err) {
      setDataForUser({});

    }

    setLoading(false);
  }, [setDataForUser]);


  function Logout() {
    const host = window.location.hostname;
    // remove any subdomain
    const domain = host.split(".").slice(-2).join(".");

    document.cookie = `ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie =
      `Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie = `Email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie =
      `Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie = `Name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie = `Phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    document.cookie = `PCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Domain=.${domain};Secure;SameSite=None;`;
    localStorage.clear();
    sessionStorage.clear();
    setUser({});
    window.location.href = "/";
  }


  React.useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);


  return (
    <AppContext.Provider
      value={{
        loading,
        setLoadingState,
        emailSendAnimation,
        setEmailSendAnimationState,
        user,
        setUser,
        setUserCookie,
        raiseToast,
        setDataForUser,
        Logout
      }}
    >
      {props.children}
      {loading && <MainLoading />}
    </AppContext.Provider>
  );
}
