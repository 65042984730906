import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated, easings } from '@react-spring/web';

const NumberAnimation = ({ number, isPercentage = false, duration = 3000, className = "text-[#3B3A5D] font-black text-lg md:text-[35px] ", symbol = "yellow-500" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const props = useSpring({
    from: { number: 0 },
    to: { number },
    config: { duration: duration, easing: easings.easeInCubic }, // Added ease-in effect
    reset: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      <h1 className={`text-center py-1 ${className}`}>
        <animated.span>
          {props.number.to((n) => n.toFixed(0))}
        </animated.span>
        <span className={`text-${symbol} font-[800] text-yellow-400`}>
          {isPercentage ? '%' : '+'}
        </span>
      </h1>
    </div>
  );
};

export default NumberAnimation;
