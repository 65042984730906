import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Dropdown = ({ label, items, width }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  // Close the dropdown if a click happens outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}
      onMouseLeave={closeDropdown}
    >
      <div
        onClick={toggleDropdown}
        role="button"
        tabIndex={0}

        className={`font-semibold flex items-center justify-between gap-2 md:w-auto w-96 cursor-pointer text-black text-[16px] lg:text-[16px] capitalize mx-2 py-2 px-2 rounded-md ${isOpen ? ' bg-[#7afe75]' : 'hover:bg-[#7afe75]'
          }`}
      >
        <h1>{label}</h1>
        <IoIosArrowDown
          className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''
            }`}
        />
      </div>
      <div className='h-2 w-full'>
      </div>
      {isOpen && (
        <ul
          className={`md:dropdown-content w-full md:absolute md:px-2 menu bg-base-100 rounded-box z-[1] p-2 md:shadow text-black transition-all duration-300 ease-in-out transform scale-100 opacity-100`}
          style={{ width }}
          onMouseLeave={closeDropdown}
        >
          {items.map((item, index) => (
            <li className="w-96 md:w-auto md:border-0 border" key={index}>
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
