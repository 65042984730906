// @ts-nocheck

import Image1 from "../assets/architecture/image1.png";
import Image2 from "../assets/architecture/image2.png";
import Image3 from "../assets/architecture/image3.png";
import Image4 from "../assets/architecture/image4.png";
import Image5 from "../assets/architecture/image5.png";
import Image6 from "../assets/architecture/image6.png";

import TextWithBack from "../interface/TextWithBack";


const ArchitectureData: TextWithBack[] = [
    {
        text: "Residential",
        back: Image1,
        desc: "We Design Memories where Dreams Find a Home"
    },
    {
        text: "Retail Spaces",
        back: Image2,
        desc: "Designing Spaces that Inspire Shopping Experiences."
    },
    {
        text: "Office Spaces",
        back: Image3,
        desc: "Creating Office Designs that Reflect Your Company's Vision and Values"
    },
    {
        text: "Apartment Complex",
        back: Image4,
        desc: "Designing Urban Sustainable Living, Where Comfort Meets Community"
    },
    {
        text: "Villas & Condos",
        back: Image5,
        desc: "Exquisite Living, Unmatched Elegance with Timeless Luxury Redefined"
    },
    {
        text: "Hospitality Spaces",
        back: Image6,
        desc: "Designing Memorable Experiences in Every Detail"
    }
]

export const graphics = [
    require("../assets/offerings/architecture/expertise/1.webp"),
    require("../assets/offerings/architecture/expertise/2.webp"),
    require("../assets/offerings/architecture/expertise/3.webp"),
    require("../assets/offerings/architecture/expertise/4.webp"),
];

export const ArchitectureProcesses = [
    {
        text: 'It all begins with dialogues about your ideas.',
        img: require("../assets/offerings/architecture/process/1.webp"),
    },
    {
        text: 'New ideas generation and brainstorming follows.',
        img: require("../assets/offerings/architecture/process/2.webp"),
    },
    {
        text: 'A concrete design plan is developed, helping to ensure there is actionability.',
        img: require("../assets/offerings/architecture/process/3.webp"),
    },
    {
        text: 'Meticulously we implement your design into reality.',
        img: require("../assets/offerings/architecture/process/4.webp"),
    },
    {
        text: 'Minor adjustments are made to make everything better.',
        img: require("../assets/offerings/architecture/process/5.webp"),
    },
    {
        text: 'Minor adjustments are made to make everything better.',
        img: require("../assets/offerings/architecture/process/6.webp"),
    },
]


export const ArchitectureOfferings = [
    {
        title: 'Residences & Enterprises',
        description: 'We specialise in designing aesthetically appealing and functional houses and commercial areas.',
        imgmain: graphics[0],
        images: [
            require("../assets/offerings/architecture/residences-enterprises/1.webp"),
            require("../assets/offerings/architecture/residences-enterprises/2.webp"),
            require("../assets/offerings/architecture/residences-enterprises/3.webp"),
            require("../assets/offerings/architecture/residences-enterprises/4.webp"),
            require("../assets/offerings/architecture/residences-enterprises/5.webp"),
            require("../assets/offerings/architecture/residences-enterprises/6.webp"),
            require("../assets/offerings/architecture/residences-enterprises/7.webp"),
            require("../assets/offerings/architecture/residences-enterprises/8.webp"),
            require("../assets/offerings/architecture/residences-enterprises/9.webp"),
            require("../assets/offerings/architecture/residences-enterprises/10.webp")
        ]
    },
    {
        title: 'Interior Design & Layout',
        description: ' We oversee interior design and arrangements, ensuring spaces are attractive and meet your individual needs.',
        imgmain: graphics[1],
        images: [
            require("../assets/offerings/architecture/interior-design-layout/1.webp"),
            require("../assets/offerings/architecture/interior-design-layout/2.webp"),
            require("../assets/offerings/architecture/interior-design-layout/3.webp"),
            require("../assets/offerings/architecture/interior-design-layout/4.webp"),
            require("../assets/offerings/architecture/interior-design-layout/5.webp"),
            require("../assets/offerings/architecture/interior-design-layout/6.webp"),
            require("../assets/offerings/architecture/interior-design-layout/7.webp"),
            require("../assets/offerings/architecture/interior-design-layout/8.webp"),
            require("../assets/offerings/architecture/interior-design-layout/9.webp"),
            require("../assets/offerings/architecture/interior-design-layout/10.webp")
        ]
    },
    {
        title: 'Innovative as well as Thorough Layouts',
        description: 'We blend creativity and detail to manage projects of all sizes, from modern offices to small home renovations.',
        imgmain: graphics[2],
        images: [
            require("../assets/offerings/architecture/innovative-layouts/1.webp"),
            require("../assets/offerings/architecture/innovative-layouts/2.webp"),
            require("../assets/offerings/architecture/innovative-layouts/3.webp"),
            require("../assets/offerings/architecture/innovative-layouts/4.webp"),
            require("../assets/offerings/architecture/innovative-layouts/5.webp"),
            require("../assets/offerings/architecture/innovative-layouts/6.webp"),
            require("../assets/offerings/architecture/innovative-layouts/7.webp"),
            require("../assets/offerings/architecture/innovative-layouts/8.webp"),
            require("../assets/offerings/architecture/innovative-layouts/9.webp"),
            require("../assets/offerings/architecture/innovative-layouts/10.webp")
        ]
    },
    {
        title: 'Individualised Areas',
        description: 'We create environments that reflect your vision; be it an opulent house or a practical office.',
        imgmain: graphics[3],
        images: [
            require("../assets/offerings/architecture/individualised-areas/1.webp"),
            require("../assets/offerings/architecture/individualised-areas/2.webp"),
            require("../assets/offerings/architecture/individualised-areas/3.webp"),
            require("../assets/offerings/architecture/individualised-areas/4.webp"),
            require("../assets/offerings/architecture/individualised-areas/5.webp"),
            require("../assets/offerings/architecture/individualised-areas/6.webp"),
            require("../assets/offerings/architecture/individualised-areas/7.webp"),
            require("../assets/offerings/architecture/individualised-areas/8.webp"),
            require("../assets/offerings/architecture/individualised-areas/9.webp"),
            require("../assets/offerings/architecture/individualised-areas/10.webp")
        ]
    },

]

export const ArchitectureFAQs =  [
    {
      "question": "What services do you provide?",
      "answer": "We offer a full range of services including architectural design, furniture layout, construction management, general design services etc. We can assist you with designing your house or remodelling an old one as well as making plans for a commercial building."
    },
    {
      "question": "How long does the design process take?",
      "answer": "The timeline for this phase varies depending on how complicated or large your project is but it takes anything from weeks to several months. We also oversee the construction after designs have been made so that everything gets done within set timelines."
    },
    {
      "question": "Can you work within my budget?",
      "answer": "Indeed, we work hand in hand with you as far as your allowance is concerned. Our designs adhere to both your taste and budgetary requirements. With that in mind, we always provide clear cost estimates and assist to offer you informed choices."
    },
    {
      "question": "Do you take care of the complete project including permits and construction?",
      "answer": "Yes, permits acquisition, contract negotiation, and construction supervision are some of the tasks which we do for our clients in their projects. We see to it that all goes well from beginning to end."
    },
    {
      "question": "What are the steps to get started working with you?",
      "answer": "Starting with us is easy. To kick off the process, schedule an appointment for an introductory meeting, during which time we will go over everything on your project so we can understand each other well on how best to move forward."
    },
    {
      "question": "In what ways does Design Elementary Company differ from others?",
      "answer": "Our main focus is always on what our customers require or would like to have done when employing us. In our work, we combine exciting styles and practical solutions with a lot of emphasis placed on details, creating a different working environment for every client who visits us, making us the best in the field."
    }
  ]


export default ArchitectureData;