
import ShowCurrentSubscription from "../../../../components/pages/subscriptions/ShowCurrentSubscription";
import React from "react";
import ProfileLayout from "../layout";

export default function MySubscriptions() {

  return (
    <>
      <ProfileLayout>
        <ShowCurrentSubscription  />
      </ProfileLayout>
    </>
  );
}
