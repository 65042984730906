import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ArchitectureData from '../../constants/ArchitectureData';

const Services = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow p-6  bg-gray-100">
        <h1 className="text-5xl font-bold text-center text-gray-800 mb-12">Our Services</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {ArchitectureData.map((service) => (
            <Link 
              key={service.text} 
              to={`/services/${service.text.toLowerCase().replace(/ /g, '-')}`}
              className="block rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transform hover:scale-105 transition-transform duration-300"
            >
              <img 
                src={service.back} 
                alt={service.text} 
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-800">{service.text}</h2>
                <p className="mt-2 text-gray-600">{service.desc}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
