const RoomType = [
  {
    id: "livingroom",
    name: "Living Room",
    value: "livingroom",
  },
  {
    id: "kitchen",
    name: "Kitchen",
    value: "kitchen",
  },
  {
    id: "diningroom",
    name: "Dining Room",
    value: "diningroom",
  },
  {
    id: "bedroom",
    name: "Bedroom",
    value: "bedroom",
  },
  {
    id: "bathroom",
    name: "Bathroom",
    value: "bathroom",
  },
  {
    id: "kidsroom",
    name: "Kids Room",
    value: "kidsroom",
  },
  {
    id: "familyroom",
    name: "Family Room",
    value: "familyroom",
  },
  {
    id: "readingnook",
    name: "Reading Nook",
    value: "readingnook",
  },
  {
    id: "sunroom",
    name: "Sun Room",
    value: "sunroom",
  },
  {
    id: "walkincloset",
    name: "Walk-in Closet",
    value: "walkincloset",
  },
  {
    id: "mudroom",
    name: "Mud Room",
    value: "mudroom",
  },
  {
    id: "toyroom",
    name: "Toy Room",
    value: "toyroom",
  },
  {
    id: "office",
    name: "Office",
    value: "office",
  },
  {
    id: "foyer",
    name: "Foyer",
    value: "foyer",
  },
  {
    id: "powderroom",
    name: "Powder Room",
    value: "powderroom",
  },
  {
    id: "laundryroom",
    name: "Laundry Room",
    value: "laundryroom",
  },
  {
    id: "gym",
    name: "Gym",
    value: "gym",
  },
  {
    id: "basement",
    name: "Basement",
    value: "basement",
  },
  {
    id: "garage",
    name: "Garage",
    value: "garage",
  },
  {
    id: "balcony",
    name: "Balcony",
    value: "balcony",
  },
  {
    id: "cafe",
    name: "Cafe",
    value: "cafe",
  },
  {
    id: "homebar",
    name: "Home Bar",
    value: "homebar",
  },
  {
    id: "study_room",
    name: "Study Room",
    value: "study_room",
  },
  {
    id: "front_porch",
    name: "Front Porch",
    value: "front_porch",
  },
  {
    id: "back_porch",
    name: "Back Porch",
    value: "back_porch",
  },
  {
    id: "back_patio",
    name: "Back Patio",
    value: "back_patio",
  },
];

export default RoomType;
