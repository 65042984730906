// TemplatePage.tsx
import React from 'react';
import HowItWorksSection from '../../components/template/HowItWorks';
import ReviewsSection from '../../components/template/Reviews';
import FAQSection from '../../components/template/FAQSection';
import NumbersSection from '../../components/template/NumbersSection';
import HomesSection from '../../components/template/Homes';
import KeyFeatures from '../../components/template/KeyFeatures';
import InteriorOfferComponent from '../../components/template/TopSection';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import data from '../../constants/ExteriorData';

const Exterior = () => {
  const { features, faqs, numbers, stepsData, topSection, reviews } = data;
  return (
    <>

      <Header />

      <InteriorOfferComponent
        headingText={topSection.headingText}
        highlightedText={topSection.highlightedText}
        headingText2={topSection.headingText2}
        subText={topSection.subText}
        video={topSection.video}
        service='Exterior'
      />

      <KeyFeatures
        features={features}
        title="Key Features"
      />

      <NumbersSection
        title="Let Our Numbers Do the Talking!"
        numbers={numbers}
      />

      <HowItWorksSection
        title="How It Works"
        steps={stepsData}
      />

      <ReviewsSection
        reviewsData={reviews}
      />

      <FAQSection
        faqData={faqs}
      />

      {/* <HomesSection
        cardData={homes}
      /> */}

      <Footer />
    </>
  );
};

export default Exterior;
