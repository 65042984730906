import React, { useState } from 'react';
import Slider from '../SliderWrapper';

const ImagesCard = ({ item, index }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div key={index} className='grid justify-items-center'>
            <div className='relative flex justify-center items-center shadow-md shadow-black/35' style={{ width: '300px', height: '200px' }}>
                <img src={item.imgmain} className='object-cover w-full h-full' alt={item.title} />
                <div className='w-full h-full absolute bg-black z-10 opacity-20'></div>
                <h1 className='absolute text-white text-2xl text-center z-30 font-bold'>{item.title}</h1>
            </div>
            <p className='text-center text-xl my-3 px-10 min-h-[60px] flex items-center justify-center'>{item.description}</p>

            <div className="flex justify-center items-end">
                <button
                    className='bg-green-500 shadow-md w-40 h-10 text-white font-semibold hover:bg-green-600 rounded-md'
                    onClick={handleModalToggle}
                >
                    View More
                </button>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className='fixed top-0 left-0 w-screen h-screen bg-black/70 flex justify-center items-center z-50'>
                    <div className='relative w-11/12 p-5 rounded-lg'>
                        <button
                            className='absolute -top-10 right-3 bg-red-500 text-white p-2 rounded-full hover:bg-red-600'
                            onClick={handleModalToggle}
                        >
                            Close
                        </button>
                        <Slider interval={1500} className="w-full p-2 rounded-lg transition-all">
                            {item.images.map((image, idx: number) => (
                                <Thumbnail key={idx} image={image} />
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImagesCard;

function Thumbnail({ image }) {
    return (
        <div className="cursor-pointer text-center md:m-2 flex flex-col items-center justify-center rounded-lg transition-all duration-150 ease-in">
            <img
                className="w-full h-96 object-cover"
                src={image}
                alt="Thumbnail"
            />
        </div>
    );
}
