
import React from "react";
import ClientSay from "../components/ClientSay";
import Footer from "../components/offers/Footer";
import Section1 from "../components/offers/Section1";
import Section2 from "../components/offers/Section2";
import Section3 from "../components/offers/Section3";
import Section4 from "../components/offers/Section4";
import Topsection from "../components/offers/Topsection";
import FAQSection from "../components/offers/FAQs";
import KeyFeatures from "../components/offers/Features";
import FormComponent from "../components/offers/Form";
import OfferHeader from "../components/offers/OfferHeader";
import Banner from "../components/interior/Banner";

const Offers = () => {
    return (
        <div className="flex flex-col min-h-screen">
     
        <OfferHeader />
          <div>
            <Topsection />
          </div>
    
          <div>
            <div className='md:hidden my-10'>
              <FormComponent />
            </div>
          </div>
    
          <KeyFeatures />
    
          <Banner />
    
          <div>
            <Section1 />
          </div>
          <Banner />
          <div
          >
            <Section2 />
          </div>
          <Banner />
          <div>
            <Section3 />
          </div>
          <Banner />
          <div>
            <Section4 />
          </div>
          <Banner />
          <div>
            <ClientSay />
          </div>
          <FAQSection />
    
          <Footer />
    
          <a
            href="https://api.whatsapp.com/send?phone=+916363759171&text=Hello,%20I%20would%20like%20to%20know%20more%20about%20Your%20Designs"
            className="fixed top-[70%] left-12 z-[1000] rounded-r-md p-[8px] md:top-[50%]"
            style={{ transform: "translate(-76%)" }}
          >
            <img
              src={require("../assets/whatsapp.png")}
              alt="whatsapp"
              className="w-[55px] bg-white rounded-full"
            />
          </a>
        </div>
      );
}

export default Offers