import { DesignType } from "../../../constants/DesignType";
import RoomType from "../../../constants/RoomTypes";
import { BASE_API_URL } from "../../../constants/data";
import { QuizDesignsInterfaceV1 } from "../../../interface/QuizDesigns";

import { Link } from "react-router-dom";
import React from "react";

import { MdDeleteOutline } from "react-icons/md";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { AppContext } from "../../../context/AppContext";

export default function ProfileDesigns() {
  const [designs, setDesigns] = React.useState<QuizDesignsInterfaceV1[]>([]);
  const { user, setLoadingState } = React.useContext(AppContext);

  const [designAI, setDesignAI] = React.useState<any>([]);
  const [width, setWidth] = React.useState(0);

  const getDesigns = React.useRef(() => { });

  getDesigns.current = async function () {
    if (!user.Phone) return;
    const res = await fetch(
      BASE_API_URL +
      "/user/india/designs?" +
      new URLSearchParams({ uid: user.Phone }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
    if (res.status === 200) {
      setDesigns(res.data);
    }

    const data = await fetch(
      BASE_API_URL + "/user/india/initialize-design?" +
      new URLSearchParams({ uid: user.Phone }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());

    if (data.status === 200) {
      let designs = data.data;

      let loopDesigns: any = [];

      designs = designs.map((design: any) => {
        if (design.designed_image.length > 1) {
          let designData = design.designed_image.map((image: any) => {
            loopDesigns.push({
              ...design,
              designed_image: image,
            });
          });
        } else {
          loopDesigns.push({
            ...design,
            designed_image: design.designed_image[0],
            file: design.file,
          });
        }
      });
      setDesignAI(loopDesigns);
    } else {
      alert(data.message);
    }
  };

  async function deleteDesign(option: any) {
    setLoadingState(true);
    let filteredDesigns = designs.filter(
      (design) => design.image !== option.image
    );

    const res = await fetch(BASE_API_URL + "/user/designs", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(option),
    }).then((res) => res.json());
    if (res.status === 200) {
      setLoadingState(false);
      setDesigns(filteredDesigns);
    } else {
      setLoadingState(false);
      return;
    }
  }

  function downloadDesign(option: any) {
    if (!option.designed_image) {
      alert("Design not found");
      return;
    }

    // create blob and download
    fetch(option.designed_image)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", option.design_name + ".jpg");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  const [type, setType] = React.useState<number>(0);

  React.useEffect(() => {
    getDesigns.current();
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <ul className="flex items-center justify-center mb-3 text-[#777E90]">
        {["Inspirations", "Design AI"].map((item, index) => {
          return (
            <li
              key={index}
              className={`w-fit cursor-pointer rounded-full py-3 lg:py-2 mx-2 px-3  duration-150  ${type === index ? "bg-[#004d3d] text-white" : ""
                }`}
              onClick={() => setType(index)}
            >
              {item}
            </li>
          );
        })}
      </ul>
      {type === 0 ? (
        <>
          {designs && designs.length !== 0 ? (
            <div className="mx-auto mt-7 w-10/12 grid gap-6 px-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {designs.map((option: QuizDesignsInterfaceV1, idx: number) => (
                <div key={idx} className="relative group shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                  {/* Delete Icon */}
                  <div
                    onClick={() => deleteDesign(option)}
                    className="absolute top-2 right-2 bg-[#479E82] p-2 rounded-full z-10 opacity-90 hover:opacity-100 transition-opacity cursor-pointer"
                  >
                    <MdDeleteOutline className="text-2xl text-white" />
                  </div>

                  {/* Image */}
                  <img
                    width={300}
                    height={300}
                    src={option.image}
                    alt={option.name}
                    className="w-full h-64 object-cover rounded-md my-1 border-[3px] transition-transform duration-200 ease-in-out transform group-hover:scale-105"
                  />
                </div>
              ))}
            </div>

          ) : (
            <div className="flex md:flex-row flex-col justify-center items-center">
              <p className="text-[16px] text-black font-semibold">
                You Don&apos;t have any favoruite designs.
              </p>

              {/* <Link
                to="/quiz/select-designs"
                className="text-blue-500 font-semibold ml-1"
              >
                Select Now.
              </Link> */}
            </div>
          )}
        </>
      ) : (
        <>
            {designAI && designAI.length !== 0 ? (
            <div className=" mx-auto  mt-7 w-[98%] grid gap-x-2 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
              {designAI.map((option: any, idx: number) => {
                return (
                  <div key={idx} className="relative">
                    <div
                      onClick={() => downloadDesign(option)}
                      className="absolute top-[4rem] right-2 bg-[#479E82] p-1 rounded-lg z-50"
                    >
                      <FaArrowAltCircleDown className="text-2xl  text-white cursor-pointer" />
                    </div>
                    <h1 className="text-[#004d3d] text-center font-semibold">
                      {option.design_name}
                    </h1>
                    <h2 className="text-[#004d3d] text-center font-semibold">
                      To :{" "}
                      {
                        RoomType.find((room) => room.value === option.room_type)
                          ?.name
                      }{" "}
                      -{" "}
                      {
                        DesignType.find(
                          (design) => design.value === option.design_type
                        )?.name
                      }{" "}
                    </h2>
                    <div className="diff z-30 my-2 h-[300px]">
                      <div className="diff-item-1">
                        <img
                          width={500}
                          height={500}
                          alt="daisy"
                          src={option.designed_image}
                        />
                      </div>
                      <div className="diff-item-2">
                        <img
                          width={500}
                          height={500}
                          alt="daisy"
                          src={option.file}
                        />
                      </div>
                      <div
                        className="diff-resizer"
                        style={{
                          width: (width || 500) / 6,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex md:flex-row flex-col justify-center items-center">
              <p className="text-[16px] text-black font-semibold">
                Generate designs in 10 Seconds
              </p>

              <Link
                to="/user/profile/design-ai"
                className="text-blue-500 font-semibold ml-1"
              >
                Generate.
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}
