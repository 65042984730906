import React from 'react';

export const whatWeOffer = [
  {
    text: "END TO END DESIGN SOLUTION",
    image: require("../../assets/offers/offerings/1.webp"),
  },
  {
    text: "2D WORKING DRAWING",
    image: require("../../assets/offers/offerings/2.webp"),
  },
  {
    text: "BOQ & COSTING",
    image: require("../../assets/offers/offerings/3.webp"),
  },
  {
    text: "CUSTOM PRODUCT DESIGN",
    image: require("../../assets/offers/offerings/4.webp"),
  },
  {
    text: "360 DEGREE VIEW",
    image: require("../../assets/offers/offerings/5.webp"),
  },
  {
    text: "3D RENDERED DESIGN",
    image: require("../../assets/offers/offerings/6.webp"),
  },
];

const KeyFeatures = () => {
  return (
    <div className="py-10 bg-[#01382A] text-white">
      <h1 className="font-bold md:text-[45px] text-[28px] text-center mb-10 w-fit mx-auto">
        WHAT WE OFFER
      </h1>

      <div className="flex justify-center items-center gap-8 md:flex-row flex-col">
        {whatWeOffer.map((item, index) => (
          <div key={index} className="flex flex-col w-44 h-44 items-center">
            <img
              src={item.image}
              className="h-24 object-contain"
              alt={item.text}
            />
            <h1 className="text-lg py-4 font-semibold text-center">
              {item.text}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyFeatures;
