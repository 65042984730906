import { BASE_API_URL } from "../../../constants/data";
import { Link } from "react-router-dom";
import React from "react";
import { AppContext } from "../../../context/AppContext";

export default function ShowCurrentSubscription() {
  const { user,setLoadingState } = React.useContext(AppContext);
  const [type, setType] = React.useState<number>(0);
  const [subscription, setSubscription] = React.useState<any>([]);
  const getSubscription = React.useRef(() => {});

  getSubscription.current = async function () {
    if(!user.Phone) return;
    setLoadingState(true);
    const data = await fetch(
      BASE_API_URL + "/user/india/check-payments?uid=" + user.Phone,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
    if (data.status === 200) {
      setSubscription(data.session);
    } else {
    }
    setLoadingState(false);
  };

  React.useEffect(() => {
    getSubscription.current();
  }, []);

  return (
    <div>
      {subscription && subscription.length !== 0 ? (
        <div>
          <ul className="flex items-center justify-center mb-3 text-[#777E90]">
            {["My Subscriptions"].map((item, index) => {
              return (
                <li
                  key={index}
                  className={`w-fit cursor-pointer rounded-full py-3 lg:py-2 mx-2 px-2  duration-150  ${
                    type === index ? "bg-[#004d3d] text-white" : ""
                  }`}
                  onClick={() => setType(index)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
          <div className="flex flex-row justify-center items-start">
            {subscription.map((sub: any, index: number) => {
              if (type === 0) {
                return (
                  <div
                    key={index}
                    className="px-3 py-4 shadow-xl border mx-3 my-2 text-black rounded-md"
                  >
                    <h2>
                      {sub.subscription}
                    </h2>
                    <h3>Rs. {sub.amount}</h3>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="flex md:flex-row flex-col justify-center items-center">
          <p className="text-[16px] text-black font-semibold">
            You Don&apos;t have any Active Design Packages
          </p>

          <Link
            to="/subscriptions"
            className="text-blue-500 font-semibold ml-1"
          >
            Choose Now.
          </Link>
        </div>
      )}
    </div>
  );
}
