import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#001F3F] text-white py-6">
      <div className="container mx-auto text-center">
        <p className="font-medium">© 2024 Design Elementary. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
