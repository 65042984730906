import React from 'react';

const faqData = [
  {
    question: "What services do you offer?",
    answer:
      "We provide Interior - Architecture design services & Products to our clients.",
  },
  {
    question: "What is the typical timeline for an Interior Design project?",
    answer:
      "The timeline for an interior design project will vary depending on the scope of the project, but it usually takes anywhere from 72 - 100 hours.",
  },
  {
    question: "What is the typical timeline for an Architectural project?",
    answer:
      "Even though Architectural projects take a bit more time than interior design projects, it usually takes around a few weeks to complete.",
  },
  {
    question: "Why should I choose you?",
    answer: `Apart from our specially customized and cost-effective services, here are a few reasons why you should go with us for all your Home or Commercial Design & Furnishing - 
        
       1.  Complimentary design consultation before purchase.
        
       2.  We provide 2D, 3D, and 3D - Rendered visualization along with a Realistic 360' video of your design, which will help you to visualize in real time.
        
       3.  Custom-themed approach to your project by our designers.
        
       4.  Our Relationship manager assigned to your Project makes sure you have a  smooth design & purchase journey with us.
        
       5.  We provide after-sale service through our robust customer care support.
        
       6.  In case of Custom design & execution Requirements, our on-ground Verified Execution Partners helps you to get your dream into reality.
        
       7.  You can select & purchase Furniture & Décor products  from our vast catalogue with the help of our designers.
        `,
  },
  {
    question:
      "How much experience do you have in the Architecture and Interior Design industry?",
    answer:
      "We have been operating in this industry for the last decade and have the most skillful team with an experience of 10 - 15 years.",
  },
  {
    question:
      "Will I have a dedicated point of contact throughout the project?",
    answer:
      "Yes, A Relationship Manager is assigned to every customer to ensure a smooth flow of communication.",
  },
  {
    question: "How will we contact our Designer & Relationship manager?",
    answer:
      "On a daily basis, you have the option to use the live chatbox on our website to communicate and maintain contact with our Designers and Relationship Managers.",
  },
  {
    question: "What is your policy on payment and payment schedules?",
    answer:
      "We have several payment packages available on our platform from where customers can choose and proceed with payments according",
  },
  {
    question: "How do I get started with my project? What is the first step?",
    answer:
      "Our procedure is pretty simple. All you have to do is to book a free consultation with our best designers, and voila you have taken the first step to bring your vision to life!",
  },
  {
    question:
      "Can you help with the procurement of furnishings and décor items for my project?",
    answer:
      "We definitely can. We have numerous partners with whom we collaborate and help our customers select their desired products without any hassle. ",
  },
  {
    question: "Can you work within my budget constraints?",
    answer:
      "We believe that great design should not be limited to those with big budgets, and our team is dedicated to providing exceptional service and tailored designs to meet the unique needs of each client.",
  },
];

const FAQSection = () => {
  return (
    <div className="bg-gray-50 py-12 px-6 md:px-20">
      <h2 className="text-center text-3xl font-semibold mb-8 text-gray-800">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div
            tabIndex={0}
            className="collapse collapse-arrow bg-white shadow-md rounded-lg"
            key={index}
          >
            <div className="collapse-title font-semibold text-cyan-900">
              {faq.question}
            </div>
            <div className="collapse-content">
              <p className="text-cyan-500">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
