
import React from "react";
import ProfileDesigns from "../../../../components/pages/my-designs/ProfileDesigns";
import ProfileLayout from "../layout";

export default function MyDesigns() {
  return (
    <>
     <ProfileLayout>
        <ProfileDesigns  />
      </ProfileLayout>
    </>
  );
}
