//@ts-nocheck 
import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import image from "../assets/sign-in.jpg";
import logo from "../assets/navlogo.svg";
import ImageUploader from '../components/ImageUploader';

const Vendor: React.FC = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const initialValues = {
        ownerName: '',
        companyName: '',
        industry: '',
        products: [
            {
                productName: '',
                images: [],
                price: '',
            },
        ],
        city: '',
        address: '',
        delivery: '',
        phone: '',
    };

    const validationSchema = Yup.object({
        ownerName: Yup.string().required('Required'),
        companyName: Yup.string().required('Required'),
        industry: Yup.string(),
        products: Yup.array().of(
            Yup.object({
                productName: Yup.string(),
                productPhotos: Yup.string(),
                price: Yup.number(),
            })
        ),
        city: Yup.string().required('Required'),
        address: Yup.string(),
        delivery: Yup.string(),
        phone: Yup.string().required('Required').max(10, 'Invalid Phone Number').min(10, 'Invalid Phone Number'),
    });


    const handleSubmit = (values: any) => {
        fetch("https://script.google.com/macros/s/AKfycbzgqm27gYeRQFevSbsvkguTfSIplitu-CaivZd__IftDzo0imEwbFkBCy73DQeLcn4/exec", {
            redirect: "follow",
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                "Content-Type": "text/plain;charset=utf-8",
            },
        })
            .then(response => response.json())
            .then(() => {
                setFormSubmitted(true);
                toast.success('Form submitted successfully!', {
                    duration: 3000,
                    position: 'top-right',
                });
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Failed to submit form. Please try again later.', {
                    duration: 3000,
                    position: 'top-right',
                });
            });
    };

    const handleNewEntry = () => {
        setFormSubmitted(false);
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-gray-100">
           <div className='w-full relative h-24 flex justify-center items-center mb-10'>
                <img src={logo} className='absolute w-56 z-10' alt="" />
                <div className='absolute w-full h-full bg-teal-200 opacity-70'></div>
                <img src={image} className="w-full object-cover h-full" alt="" />
            </div>
            <div className="card w-full max-w-4xl md:shadow-lg md:bg-white mx-2 p-4">
                {formSubmitted ? (
                    <div className="text-center p-4">
                        <h2 className="text-lg font-semibold text-orange-400 mb-4">Thank You!</h2>
                        <p className="text-sm mb-4">Your form has been successfully submitted.</p>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={handleNewEntry}
                        >
                            Fill Another Entry
                        </button>
                    </div>
                ) : (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                {/* Form sections container */}
                                <div className="flex flex-col md:flex-row gap-4">
                                    {/* Left side: General form fields */}
                                    <div className="w-full md:w-1/2">
                                        <div className="form-control mb-2">
                                            <label htmlFor="ownerName" className="label font-medium text-sm">Owner Name*</label>
                                            <Field name="ownerName" type="text" className="input input-bordered input-xs" />
                                            <ErrorMessage name="ownerName" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="companyName" className="label font-medium text-sm">Company Name*</label>
                                            <Field name="companyName" type="text" className="input input-bordered input-xs" />
                                            <ErrorMessage name="companyName" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="industry" className="label font-medium text-sm">Industry*</label>
                                            <Field name="industry" as="select" className="select select-bordered select-xs">
                                                <option value="">Select</option>
                                                <option value="Interior materials">Interior materials</option>
                                                <option value="Construction materials">Construction materials</option>
                                                <option value="Home Decor">Home Decor</option>
                                            </Field>
                                            <ErrorMessage name="industry" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="phone" className="label font-medium text-sm">Phone*</label>
                                            <Field name="phone" type="text" className="input input-bordered input-xs" />
                                            <ErrorMessage name="phone" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="city" className="label font-medium text-sm">City*</label>
                                            <Field name="city" type="text" className="input input-bordered input-xs" />
                                            <ErrorMessage name="city" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="address" className="label font-medium text-sm">Address</label>
                                            <Field name="address" type="text" className="input input-bordered input-xs" />
                                            <ErrorMessage name="address" component="div" className="text-red-500 text-xs" />
                                        </div>

                                        <div className="form-control mb-2">
                                            <label htmlFor="delivery" className="label font-medium text-sm">Delivery*</label>
                                            <Field name="delivery" as="select" className="select select-bordered select-xs">
                                                <option value="">Select</option>
                                                <option value="Home city">Home city</option>
                                                <option value="Pan India">Pan India</option>
                                            </Field>
                                            <ErrorMessage name="delivery" component="div" className="text-red-500 text-xs" />
                                        </div>
                                    </div>

                                    {/* Right side: Dynamic Products Section */}
                                    <div className='md:w-1/2 h-full'>
                                        <label htmlFor="products" className="label font-medium text-sm">Products</label>
                                        <div className="w-full md:w-full md:h-[27rem] md:overflow-y-auto">
                                            <FieldArray name="products">
                                                {({ remove, push }) => (
                                                    <div className='my-2'>
                                                        {values.products.map((_, index) => (
                                                            <div key={index} className="border p-2 mb-4 rounded-md bg-gray-50">
                                                                <h3 className="font-medium text-sm">Product {index + 1}</h3>

                                                                <div className="form-control mb-2">
                                                                    <label htmlFor={`products.${index}.productName`} className="label font-medium text-sm">Name</label>
                                                                    <Field name={`products.${index}.productName`} type="text" className="input input-bordered input-xs" />
                                                                </div>

                                                                {/* ImageUploader Component */}
                                                                <ImageUploader onUpload={(urls) => {
                                                                    const updatedImages = [...urls];
                                                                    setFieldValue(`products.${index}.images`, updatedImages);
                                                                }} />

                                                                <div className="form-control mb-2">
                                                                    <label htmlFor={`products.${index}.price`} className="label font-medium text-sm">Starting Price (Rs.)</label>
                                                                    <Field name={`products.${index}.price`} type="number" className="input input-bordered input-xs" />
                                                                </div>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-error btn-xs mt-2"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        ))}

                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary btn-xs mt-2"
                                                            onClick={() => push({ productName: '', productPhotos: '', price: '' })}
                                                        >
                                                            Add Product
                                                        </button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button at the Bottom */}
                                <div className="form-control mt-4 grid justify-items-center">
                                    <button type="submit" className="btn btn-primary btn-sm md:w-1/6" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default Vendor;