import React from "react";
import ChildrenInterface from "../../../interface/Children";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import { AppContext } from "../../../context/AppContext";

export default function ProfileLayout(props: ChildrenInterface) {
  const navigate = useNavigate();

  const { Logout, user } = React.useContext(AppContext)

  const lastPath = window.location.pathname.split("/").pop();

  React.useEffect(() => {
    if (!user?.Id) {
      navigate("/login?forwardUrl=/user/profile/design-ai");
    }
  }, []);

  return (
    <>
      <Header />
      <div className="min-h-[88vh] flex justify-between flex-col">
        <div className="bg-[#fbfbfd]">
          <div className="mx-auto w-11/12 py-4 ">
            <div className="mt-6 flex flex-col items-start justify-center md:flex-row md:justify-between">
              <div className="flex w-full rounded-xl border-[2px] border-solid border-[#E6E8EC] bg-white p-4 md:block md:w-auto ">
                <ul className="flex w-full overflow-scroll scroll-hide items-center justify-start text-[#777E90] md:block md:w-auto">
                  {/* <Link to="/user/profile/details">
                    <li
                      className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${
                        lastPath === "details" ? "bg-[#1B454D] text-white" : ""
                      }`}
                    >
                      My Details
                    </li>
                  </Link> */}
                  {/* <a
                    href="https://b2c.designelementary.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li
                      className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${
                        lastPath === "dashboard" ? "bg-[#1B454D] text-white" : ""
                      }`}
                    >
                      Dashboard
                    </li>
                  </a> */}

                  <Link to="/user/profile/my-subscriptions">
                    <li
                      className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${lastPath === "my-subscriptions"
                          ? "bg-[#1B454D] text-white"
                          : ""
                        }`}
                    >
                      Design Package
                    </li>
                  </Link>
                  {/* {formData.uid && (
							<Link to="_blank" href="https://crm.dzylo.com/#/login">
								<li
									className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${
										lastPath === 3 ? "bg-[#1B454D] text-white" : ""
									}`}
								>
									My Projects
								</li>
							</Link>
						)} */}
                  <Link to="/user/profile/my-designs">
                    <li
                      className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${lastPath === "my-designs"
                          ? "bg-[#1B454D] text-white"
                          : ""
                        }`}
                    >
                      Inspirations
                    </li>
                  </Link>
                  <Link to="/user/profile/design-ai">
                    <li
                      className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${lastPath === "design-ai"
                          ? "bg-[#1B454D] text-white"
                          : " bg-orange-500 text-white"
                        }`}
                    >
                      DesignAI
                    </li>
                  </Link>
                  <Link to="/user/profile/support">
                    <li
                      className={` my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3 text-[14px] mx-2 md:mx-[unset] font-[700] leading-[16px] duration-150 md:my-auto md:mb-6 ${lastPath === "support" ? "bg-[#1B454D] text-white" : ""
                        }`}
                    >
                      Support Center
                    </li>
                  </Link>

                  <li
                    onClick={Logout}
                    className={`my-1 w-max md:w-fit cursor-pointer rounded-full py-1 px-3  text-[14px] font-[700] leading-[16px] duration-150 md:my-auto `}
                  >
                    Logout
                  </li>
                </ul>
              </div>
              <div className="mx-auto my-2 w-full md:my-0 md:w-10/12 shadow-2xl">
                <div className=" rounded-xl p-4 bg-white relative">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
