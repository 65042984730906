import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import BlogCardInterface from '../interface/BlogCardInterface';

interface BlogContextType {
  blogs: BlogCardInterface[] | any[];
  interiorDesignBlogs: BlogCardInterface[] | any[];
  architectsBlogs: BlogCardInterface[] | any[];
  deIdeasBlogs: BlogCardInterface[] | any[];
  fetchBlogById: (id: string) => BlogCardInterface | undefined;
  fetchAllBlogs: () => Promise<void>;
}

const BlogContext = createContext<BlogContextType | undefined>(undefined);

export const useBlogContext = () => {
  const context = useContext(BlogContext);
  if (!context) {
    throw new Error('useBlogContext must be used within a BlogProvider');
  }
  return context;
};

export const BlogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [blogs, setBlogs] = useState<BlogCardInterface[] | any[]>([]); 
  const [interiorDesignBlogs, setInteriorDesignBlogs] = useState<BlogCardInterface[] | any[]>([]);
  const [architectsBlogs, setArchitectsBlogs] = useState<BlogCardInterface[] | any[]>([]);
  const [deIdeasBlogs, setDeIdeasBlogs] = useState<BlogCardInterface[] | any[]>([]);
  
  const [loading, setLoadingState] = useState(true);

  const fetchAllBlogs = async () => {
    setLoadingState(true);
    try {
      const blogsCollection = collection(firestore, "blogs");
      const blogsSnapshot = await getDocs(blogsCollection);
      const data = blogsSnapshot.docs.map((doc) => doc.data());

      // Set all blogs
      setBlogs(data);
      
      // Filter and set blogs by category
      setInteriorDesignBlogs(data.filter((blog) => blog.category === "interior"));
      setArchitectsBlogs(data.filter((blog) => blog.category === "architect"));
      setDeIdeasBlogs(data.filter((blog) => blog.category === "de-ideas"));
      
      setLoadingState(false);
    } catch (error) {
      console.error("Error fetching blogs: ", error);
    }
  };

  const fetchBlogById = (id: string) => blogs.find((blog) => blog.id === id);

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  return (
    <BlogContext.Provider value={{ 
      blogs, 
      interiorDesignBlogs, 
      architectsBlogs, 
      deIdeasBlogs, 
      fetchBlogById, 
      fetchAllBlogs 
    }}>
      {children}
    </BlogContext.Provider>
  );
};
