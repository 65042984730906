import NumberAnimation from './NumberAnimation';
import FormComponent from './Form';
import React from 'react';


const Topsection = () => {
  return (
    <div className="flex-grow flex flex-col md:flex-row px-10  bg-image-1">
      {/* Left animation for the text section */}
      <div
        className="my-8 md:w-1/2 md:mx-10"
      >
        <div className="md:w-[70%] w-full text-white">
          <h1 className="text-2xl text-yellow-400 leading-8 md:text-[3rem] font-semibold md:leading-[56px] tracking-wide">
            Your Dream, Our <br /> Architectural <br />
            Solution
          </h1>
          <p className="md:text-2xl text-green-300 py-6 ">
            Your Architectural Needs, Our Priority <br /> Personalized Architectural Solutions for You
          </p>
          <div className="py-2">
            <a
              href={"https://justcall.io/calendar/DesignElementary"}
              className="bg-[#E93B3B] shadow-lg text-white text-sm md:text-lg font-semibold py-2 px-4 md:py-3 md:px-6 rounded-3xl mr-2"
            >
              Book a consultation &rarr;
            </a>
          </div>
          <div className="flex py-10 justify-between">
            <div>
              <NumberAnimation number={1000} duration={2000}></NumberAnimation>
              <p className="text-white text-lg md:text-base text-center">
                Property Designed
              </p>
            </div>
            <div>
              <NumberAnimation number={99} duration={2500} isPercentage={true}></NumberAnimation>
              <p className="text-white text-lg md:text-base text-center">
                Customer satisfaction
              </p>
            </div>
            <div>
              <NumberAnimation number={60} duration={3000}></NumberAnimation>
              <p className="text-white text-lg md:text-base text-center">
                Locations Served
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right animation for the form */}


      <div
        className='md:w-[50%] w-full'
      >
        <div className='md:block hidden'>
          <FormComponent />
        </div>
      </div>
    </div>
  );
};

export default Topsection;
