const data = {

  topSection: {
    headingText: "Elevate your property with",
    highlightedText: "stunning ",
    subText: "Discover unparalleled creativity and reliable service with NatureCraft",
    headingText2: "landscapes",
    video: require('../assets/design-ideas/Landscape/video.mp4')
  },

  features: [
    {
      title: 'Façade design and renovation',
      image: require('../assets/design-ideas/Landscape/key-features/1.png')
    },
    {
      title: 'Energy-efficient solutions',
      image: require('../assets/design-ideas/Landscape/key-features/2.png')
    },
    {
      title: 'Structural enhancements',
      image: require('../assets/design-ideas/Landscape/key-features/3.png')
    },
    {
      title: 'Aesthetic improvement plans',
      image: require('../assets/design-ideas/Landscape/key-features/4.png')
    },
  ],

  numbers: [
    { number: 50, label: 'Interior Experts', symbol: '+', duration: 2500 },
    { number: 60, label: 'Location Served', symbol: '+', duration: 2500 },
    { number: 1000, label: 'Happy Clients', symbol: '+', duration: 2500 },
  ],

  stepsData: [
    {
      title: 'Client Meeting',
      description: 'Understand exterior design requirements.',
      image: require('../assets/design-ideas/Landscape/how-it-works/1.png')
    },
    {
      title: 'Site Evaluation',
      description: 'Examine site for exterior possibilities.',
      image: require('../assets/design-ideas/Landscape/how-it-works/2.png')
    },
    {
      title: 'Design Proposal',
      description: 'Present exterior design concepts.',
      image: require('../assets/design-ideas/Landscape/how-it-works/3.png')
    },
    {
      title: 'Material Selection',
      description: 'Choose appropriate materials and finishes.',
      image: require('../assets/design-ideas/Landscape/how-it-works/4.png')
    },
    {
      title: 'Execution',
      description: 'Implement exterior design plan.',
      image: require('../assets/design-ideas/Landscape/how-it-works/5.png')
    },
    {
      title: 'Final Walkthrough',
      description: 'Review completed exterior work with client.',
      image: require('../assets/design-ideas/Landscape/how-it-works/6.png')
    },
  ],

  faqs: [
    {
      question: 'What does exterior design include?',
      answer:
        'Exterior design includes the planning and styling of the outer appearance of a building, involving elements like facades, color schemes, landscaping, and material selection.',
    },
    {
      question: 'Can you help with both residential and commercial exterior designs?',
      answer:
        'Yes, we offer exterior design services for both residential and commercial properties.',
    },
    {
      question: 'How do you approach the design of a building’s exterior?',
      answer:
        'We begin with a consultation to understand your preferences and needs, considering sustainability, materials, and landscaping for a cohesive design.',
    },
    {
      question: 'Can you incorporate eco-friendly elements into exterior designs?',
      answer:
        'Yes, we can integrate eco-friendly elements like energy-efficient lighting and sustainable materials into your design.',
    },
    {
      question: 'How long does the exterior design process take?',
      answer:
        'The duration depends on the scope of the project. We provide a timeline during consultation.',
    },
    {
      question: 'Do you offer 3D renderings for exterior designs?',
      answer:
        'Yes, 3D renderings are provided to help visualize the final design before construction.',
    },
    {
      question: 'What factors should I consider when choosing an exterior design?',
      answer:
        'Consider factors like architectural style, local climate, maintenance, energy efficiency, and landscaping.',
    },
    {
      question: 'How do I get started with your exterior design services?',
      answer:
        'Contact us for a consultation, and we’ll assess your property and provide design ideas.',
    },
  ],
  reviews: [
    {
      review: "Outstanding design. They captured our vision with precision and efficiency. Highly satisfied!",
      name: "Mitali Sinha",
      avatar: require("../assets/testimonials/mitali-sinha.jpeg"),
      rating: 5
    },
    {
      review: "Transformed our space beautifully. A seamless process from start to finish. Highly recommended.",
      name: "P. Gowda",
      avatar: require("../assets/testimonials/p-gowda.jpeg"),
      rating: 4
    },
    {
      review: "They listened, they delivered. Impressed by their service.",
      name: "Nitin Prajapat",
      avatar: require("../assets/testimonials/nitin-prajapat.jpeg"),
      rating: 4
    },
    {
      review: "Fantastic architecture design & affordable.",
      name: "Snehal Raj",
      avatar: require("../assets/testimonials/snehal-raj.jpeg"),
      rating: 5
    },
  ]
};

export default data;
