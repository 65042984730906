import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArchitectureData from '../../constants/ArchitectureData';
import { FaArrowLeft } from 'react-icons/fa';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const ServiceDetail = () => {
  const location = useLocation();
  const serviceId = location.pathname.split('/')[2];

  const service = ArchitectureData.find(
    (item) => item.text.split(' ').join('-').toLowerCase().trim() === serviceId
  );

  if (!service) {
    return <div className="text-center text-2xl p-8">Service not found</div>;
  }

  return (
    <div>
     <Header />
      <div className="flex flex-col min-h-screen bg-gray-100">
        <div className="flex flex-col lg:flex-row flex-grow p-4 md:p-6">
          {/* Service Content */}
          <div className="w-full lg:w-2/3 bg-white shadow-lg rounded-lg p-4 md:p-6 relative">
            <Link
              to="/services"
              className="pb-2 top-4 left-4 text-gray-700 hover:text-gray-900"
            >
              <FaArrowLeft className="h-6 w-6" />
            </Link>
            <img
              src={service.back}
              alt={service.text}
              className="w-full h-48 md:h-64 object-cover rounded-t-lg shadow-md"
            />
            <div className="p-4 md:p-6">
              <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-2 md:mb-4">
                {service.text}
              </h1>
              <p className="text-base md:text-lg text-gray-600">{service.desc}</p>
            </div>
          </div>

          {/* Other Services */}
          <div className="w-full lg:w-1/3 mt-6 lg:mt-0 lg:ml-6">
            <div className="bg-white shadow-lg rounded-lg p-4">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">
                Other Services
              </h2>
              <ul className="space-y-4">
                {ArchitectureData.filter(
                  (item) => item.text.toLowerCase().replace(/ /g, '-') !== serviceId
                ).map((item) => (
                  <li key={item.text}>
                    <Link
                      to={`/services/${item.text.toLowerCase().replace(/ /g, '-')}`}
                      className="block p-3 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                    >
                      <div className="flex items-center">
                        <img
                          src={item.back}
                          alt={item.text}
                          className="w-10 h-10 md:w-12 md:h-12 object-cover rounded-lg mr-4"
                        />
                        <span className="text-sm md:text-lg text-gray-700">
                          {item.text}
                        </span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServiceDetail;
